import { setUTMCookie } from './util/cookies';
import { focusFirstSearchForm } from './util/functions';
import { handleSubmit } from './helper/handleSubmit';

(() => {
  //DOM element targets
  const SEARCH_FORMS: NodeListOf<HTMLFormElement> = document.querySelectorAll('.api-search-form');

  //INITIALIZE
  setUTMCookie();
  focusFirstSearchForm(SEARCH_FORMS);

  //EVENT LISTENERS
  SEARCH_FORMS.forEach(function (searchForm) {
    searchForm.addEventListener('submit', e => {
      handleSubmit(e, searchForm);
    });
  });
})();
